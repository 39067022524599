import './assets/css/style.css';
import './App.css';

import React, { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingsData } from './redux/Slice/settingSlice';
import { SettingApiData } from "./service/apiService";
import Loading from './pages/Loader/Loader';
import useSecurity from './components/Security/Security';
// Lazy load components
const Home = lazy(() => import('./components/Home/Home'));
const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./pages/Footer'));
const Wishlist = lazy(() => import('./components/Wishlist/Wishlist'));
const Login = lazy(() => import('./components/Auth/Login'));
const Checkoutcart = lazy(() => import('./components/Checkout/Checkoutcart'));
const MainCategory = lazy(() => import('./components/Category/MainCategory'));
const Register = lazy(() => import('./components/Auth/Register'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword'));
const ProductIndex = lazy(() => import('./components/Product/ProductIndex'));
const Productdetail = lazy(() => import('./pages/views/Product/Productdetail'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Faq = lazy(() => import('./components/Faq/Faq'));
const OtpPage = lazy(() => import('./pages/views/Auth/OtpPage'));
const ContactUs = lazy(() => import('./components/ContactUs/ContactUs'));
const CheckoutAddress = lazy(() => import('./components/Checkout/CheckoutAddress'));
const Invoice = lazy(() => import('./pages/views/template/invoice'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Design = lazy(() => import('./pages/views/Design/Design'));
const Men = lazy(() => import('./pages/views/Design/Men/Men'));
const Women = lazy(() => import('./pages/views/Design/Women/Women'));
const Womens = lazy(() => import('./pages/views/Design/Womens/Womens'));
const HomeLiving = lazy(() => import('./pages/views/Design/HomeLiving/HomeLiving'));
const Electronic = lazy(() => import('./pages/views/Design/Electronic/Electronic'));
const Beauty = lazy(() => import('./pages/views/Design/Beauty/Beauty'));
const CampaignList = lazy(() => import('./pages/views/Campaign/CampaignList'));
const Page = lazy(() => import('./pages/views/Page/Page'));
const PrivacyPolicy = lazy(() => import('./pages/views/PrivacyPolicy/PrivacyPolicy'));
const CategoryPage = lazy(() => import('./pages/views/Product/CategoryPage'));
const Preordercart = lazy(() => import('./components/PreOrder/Preordercart'));
const BlogsList = lazy(() => import('./pages/views/Blogs/BlogsList'));
const BlogDetails = lazy(() => import('./pages/views/Blogs/BlogDetails'));
const OrderDetailsPage = lazy(() => import('./components/OrderPage/OrderDetailsPage'));
const DhashboardPage = lazy(() => import('./components/OrderPage/DhashboardPage'));
const SubPages = lazy(() => import('./pages/views/SubPages/SubPages'));
const ExplorePage = lazy(() => import('./pages/views/Design/explore/ExploreList'));
function App() {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
 useSecurity();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    getSettingData();
  }, []);
  const getSettingData = async () => {
    try {
      const setting = await SettingApiData();
      dispatch(setSettingsData(setting.data));
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };
  const settingsData = useSelector((state) => state.settings.settingsData);
  const siteLogo = settingsData.find((setting) => setting.key_name === "FAV_ICON");
  const siteName = settingsData.find((setting) => setting.key_name === "SITE_NAME");
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href =  AppUrl + siteLogo?.key_value;
  }, [siteLogo?.key_value]);
  useEffect(() => {
    document.title = siteName?.key_value;
  }, [siteName?.key_value]);
  const isHeaderAndFooterRequired = ![
    '/checkout/cart', '/invoice', '/checkout/address',
    '/design', '/design/mens', '/design/womens',
    '/design/home-and-living', '/design/beauty',
    '/design/electronic', '/design/explore',
    '/otp-verify', '/checkout/preorder'
  ].includes(location.pathname);
  return (
    <div >
      {isHeaderAndFooterRequired && (
          <Header />
      )}
      <Suspense fallback={<div className='vh-100 d-flex justify-content-center' style={{background:'#F3F5F0'}}><Loading /></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/page/:slug' element={<Page />} />
          <Route path="/design" element={<Design />} />
          <Route path="/design/mens" element={<Men />} />
          <Route path="/design/women" element={<Women />} />
          <Route path="/design/womens" element={<Womens />} />
          <Route path="/design/home-and-living" element={<HomeLiving />} />
          <Route path="/design/electronic" element={<Electronic />} />
          <Route path="/design/beauty" element={<Beauty />} />
          <Route path="/order-details" element={<DhashboardPage />} />
          <Route path="/my/ordersPage/:orderId" element={<OrderDetailsPage />} />
          <Route path="/invoice/:orderId" element={<PrivateRoute><Invoice /></PrivateRoute>} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password/reset" element={<ForgotPassword />} />
          <Route path="/otp-verify" element={<OtpPage />} />
          <Route path="/wishlist" element={<PrivateRoute><Wishlist /></PrivateRoute>} />
          <Route path="/category" element={<MainCategory />} />
          <Route path="/shop" element={<ProductIndex />} />
          <Route path='/shop/:categoryName' element={<CategoryPage />} />
          <Route path="/product_detail" element={<Productdetail />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path='/campaign-list' element={<CampaignList />} />
          <Route path="/checkout/preorder" element={<PrivateRoute><Preordercart /></PrivateRoute>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/blogs' element={<BlogsList />} />
          <Route path='/blogs-details' element={<BlogDetails />} />
          <Route path="/checkout/cart" element={<PrivateRoute><Checkoutcart /></PrivateRoute>} />
          <Route path="/checkout/address" element={<PrivateRoute><CheckoutAddress /></PrivateRoute>} />
          <Route path="*" element={<PageNotFound />} />
          <Route path='/explore' element={<ExplorePage />} />
          <Route path='/sub-pages' element={<SubPages />} />
        </Routes>
      </Suspense>
      <ToastContainer
        toastStyle={{
          fontSize: '15px',
          fontWeight: 'bold',
          color: 'white',
          backgroundColor: '#F6A709',
          borderRadius: '5px',
          padding: '10px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
          width: '800px',
          maxWidth: '100%',
          margin: '0 auto',
          textAlign: 'center',
          height: '80px',
          minHeight: '80px',
        }}
        toastClassName="custom-toast"
      />
      {isHeaderAndFooterRequired && (
        <Suspense fallback={''}>
          <Footer />
        </Suspense>
      )}
    </div>
  );
}
export default App;