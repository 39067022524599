import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiService = axios.create({
  baseURL: API_BASE_URL,
});
const token = localStorage.getItem('token');

export const signup = async (userData) => {
  try {
    userData.phone_no = parseInt(userData.phone_no);
    const response = await apiService.post("/register", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (userData) => {
  try {
    const response = await apiService.post("/login", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CategoryMainDropdown = async () => {
  try {
    const response = await apiService.get("/dropdown-main-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const CategorySubDropdown = async () => {
  try {
    const response = await apiService.get("/dropdown-sub-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const pageListData = async (token, pageId) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiService.get(`/get-page-data/${pageId}`, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    try {
      const response = await apiService.get(`/get-page-data/${pageId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

};
export const fetchUserData = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await apiService.get('/user', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const logoutService = async (token) => {
  if (token) {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const response = await apiService.get('/logout', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('Token not provided');
  }
};


export const getAllProductsAPICall = async (value) => {
  try {
    const response = await apiService.get(`/get-shop-products/${value}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesOptionsAPICall = async () => {
  try {
    const response = await apiService.get("/dropdown-sub-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBrandOptionsAPICall = async () => {
  try {
    const response = await apiService.get("/all-brands");
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getCategoryProductsAPICall = async (productID) => {
  try {
    const response = await apiService.get(`get-category-products/${productID}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFilterProductsAPICall = async (CategoryID,BrandaID,minPrice,maxPrice) => {
  try {
    const response = await apiService.get(`get-filter-products/${CategoryID}/${BrandaID}/${minPrice}/${maxPrice}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getCampaignList = async () => {
  try {
    const response = await apiService.get("/get-campaign-page/1");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSingleProduct = async (id) => {
  try {
    const response = await apiService.get(`/get-product/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSimilarProduct = async (id) => {
  try {
    const response = await apiService.get(`/get-similar-products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getProductImages = async (id) => {
  try {
    const response = await apiService.get(`/get-product-assets/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getImageVarients = async (id) => {
  try {
    const response = await apiService.get(`/get-product-variant/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const addToCart = async (token, id, productVarientId) => {
  try {
    if (token) {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const body = {
        product_id: id,
        quantity: 1,
        variant_id: productVarientId,
        attribute_id: 0
      }
      const response = await apiService.post('/add-to-cart', body, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First"
      }
    }
  } catch (error) {
    throw error;
  }
}
export const addToWishList = async (token, id) => {
  try {
    if (token) {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const body = {
        product_id: id,
      }
      const response = await apiService.post('/add-wishlist', body, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First"
      }
    }
  } catch (error) {
    return 403;
  }
}

export const getCartItemsListAPICall = async (token) => {

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('get-user-cart', config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }

}

export const getCartAmountListAPICall = async (token) => {

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('get-cart-total', config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }

}

export const updateCartProductQuanityAPICall = async (token,payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post('update-to-cart', payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}

export const deleteSingleCartItemAPICall = async (payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post('remove-add-to-cart-product', payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}

export const epmtyCartItemsAPICall = async () => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('delete-all-cart', config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}

export const saveBillingAndShippingAddressAPICall = async (payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post('store-billing-shipping-address', payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}

export const getShippingAddressAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('get-shipping-addresses', config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}
export const getBillingAddressAPICall = async (token) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.get('get-billing-addresses', config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}
export const verifyOtp = async (emailId) => {
  try {
    const payload = {
      email: emailId
    }
    const response = await apiService.post('/resend-otp', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const Makepayment = async (payload) => {
  try {
    if (token) {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const response = await apiService.post('/place-order', payload, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First"
      }
    }
  } catch (error) {
    throw error;
  }
}

export const preOrder = async (token, id, productVarientId) => {
  try {
    if (token) {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const body = {
        product_id: id,
        quantity: 1,
        variant_id: productVarientId,
        attribute_id: 0
      }
      const response = await apiService.post('/add-pre-order-cart', body, config);
      return response.data;
    } else {
      return {
        status: 401,
        message: "Please Login First"
      }
    }
  } catch (error) {
    throw error;
  }
}
export const checkOtp = async (payload) => {
  try {

    const response = await apiService.post('/check-otp', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const applyCouponCode = async (token, coupon) => {
  try {
    if (token) {

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const body = {
        coupon_code: coupon,
      }
      const response = await apiService.post('/apply-coupon', body, config);
      return response.data;
    } else {
      throw new Error(401);
    }
  } catch (error) {
    throw error;
  }
}

export const sentSpinnerCouponAPICall = async (token, payload) => {
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await apiService.post('/spinner-win', payload, config);
    return response.data;
  } else {
    return {
      status: 401,
      message: "Please Login First!"
    }
  }
}
export const userWishList = async (token) => {
  if (token) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const response = await apiService.get('/get-user-whishlist', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('Token not provided');
  }
};
export const getGlobalSearchAPICall = async (searchTerm) => {
  try {
    const response = await apiService.get(`/get-global-search/${searchTerm}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
// {Api to get the pre order details }
export const getPreOrderListAPICall = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const response = await apiService.get('get-pre-order-cart', config);
      return {
        status_code: response.status,
        data: response.data
      };
    } catch (error) {
      console.error("Error fetching pre-order list:", error);
      return {
        message: "An error occurred while fetching pre-order items"
      };
    }
  } else {
    return {
      status_code: 401,
      message: "Please Login First"
    };
  };
}
export const SettingApiData = async () => {
  try {
    const response = await apiService.get("/settings-data");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchCouponData = async (token) => {
  if (token) {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,

      },
    };
    try {
      const response = await apiService.get('/all-coupons', config);
      return response.data;
    } catch (error) {
      throw error;
    }
  } else {
    return null;
  }
};
export const forgetPassword = async (email) => {
  try {
    const response = await apiService.post('/forget-password', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// {getting the OrderList data }

export const getmyOrderList = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const response = await apiService.get('/my-order-list', config);
      return {
        status_code: response.status,
        data: response.data
      };
    } catch (error) {
      console.error("Error fetching my order list:", error);
      return {
        message: "An error occurred while fetching orders List"
      };
    }
  } else {
    return {
      status_code: 401,
      message: "Please Login First"
    };
  };
}


// {getting the orderlist Details}
export const getmyOrderListDetails = async (id) => {
  const token = localStorage.getItem('token');
  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const response = await apiService.get(`/my-order-detail/${id}`, config);
      return {
        status_code: response.status,
        data: response.data
      };
    } catch (error) {
      console.error("Error fetching my order list details:", error);
      return {
        message: "An error occurred while fetching orders List details"
      };
    }
  } else {
    return {
      status_code: 401,
      message: "Please Login First"
    };
  };
}
export const BlogsListApi = async () => {
  try {
    const response = await apiService.get("/get-blogs");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const BlogsCategoryApi = async () => {
  try {
    const response = await apiService.get("/blog-categories-dropdown");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const BlogsTagsApi = async () => {
  try {
    const response = await apiService.get("/blog-tags-dropdown");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const BlogsCategoryData = async (id) => {
  try {
    const response = await apiService.get(`/get-category-blogs/${id}`);
   
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const BlogsTagsData = async (id) => {
  try {
    const response = await apiService.get(`/get-tag-blogs/${id}`);
   
    return response.data;
  } catch (error) {
    throw error;
  }
};  

export const SingleBlog = async (id) => {
  try {
    const response = await apiService.get(`/get-single-blog/${id}`);
   
    return response.data;
  } catch (error) {
    throw error;
  }
};  
export const resentBlogs = async () => {
  try {
    const response = await apiService.get("/recent-blogs");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const cancelOrder = async (orderCode) => {
  const token = localStorage.getItem('token');

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const body = {
        order_code: orderCode,
      }
      const response = await apiService.post('/my-order-cancel',  body , config);
      if(response.status=== 200){
        toast.success('Order Cancel Successfully')
      }
      return response.data;
      
    } catch (error) {
      console.error('Error cancelling order:', error);
      throw error; 
    }
  } 
};
export const orderReturnRequest = async (orderReturnRequestCode) => {
  const token = localStorage.getItem('token');

  if (token) {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    try {
      const body = {
        order_code: orderReturnRequestCode,
      }
      const response = await apiService.post('/order-return-request',  body , config);
      if(response.status=== 200){
        toast.success('Order Return Successfully')
      }
      return response.data;
      
    } catch (error) {
      console.error('Error cancelling order:', error);
      throw error; 
    }
  } 
};
export const getSubPagesDetails = async () => {
  try {
    const response = await apiService.get("/get-sub-pages");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSubPageDetails = async (id) => {
  try {
    const response = await apiService.get(`/sub-page-detail/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const emailSubscribe = async (subscribeEmail)=> {
  try {
    const subscribeData = {
      email: subscribeEmail
    }
    const response = await apiService.post('/subscribe',subscribeData)
    if(response.data.status_code === 200){
      toast.success(response.data.message)
    }
    return response.data

  } catch (error) {
    if(error.response){
      const responseError = error.response.data;
      toast.error(responseError.data.error);
    }
  }
}
export const contactUsSubmit = async(contactDetails)=>{
try {
  const contact ={
    ...contactDetails,
    phone_no: parseInt(contactDetails.phone_no)
  }
  const response = await apiService.post('/submit-contact-us', contact)
  if(response.data.status_code === 200){
    toast.success(response.data.message)
  }
  return response.data
} catch (error) {
}
}
export const countryList = async ()=>{
  try{
      const response = await apiService.get('/get-countries');
      return response.data;
    }catch(error){
    }
  }
export const faqCategoriesDropdown = async()=>{
  try {
    const response = await apiService.get('/faq-categories-dropdown')
    return response.data
    
  } catch (error) {
    
  }
}

export const priceFilterRangeGetAPICall = async()=>{
  try {
    const response = await apiService.get('/price-range-data')
    return response.data
    
  } catch (error) {
    
  }
}

export const faqCategorieFilter = async (id)=>{
  try {
    const response = await apiService.get(`get-category-faqs/${id}`)
    return response.data
    
  } catch (error) {
    
  }
}

export const stateList = async (id)=>{
  try{
      const response = await apiService.get(`/get-states/${id}`);
      return response.data;
    }catch(error){

  }
}
export const cityList = async (id)=>{
  try{
      const response = await apiService.get(`/get-cities/${id}`);
      return response.data;
    }catch(error){

  }
}
export const removeWishlist = async (id, token) => {
  try {
    if (token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const payload = {
        product_id: id,
      };
      const response = await apiService.post(
        "/remove-wishlist",
        payload,
        config
      );
      return response.data;
    }
  } catch (error) {}
};


export default apiService;
